import { ButtonHTMLAttributes, forwardRef, MouseEvent, ReactNode } from 'react';
import cn from 'classnames';
import { useRouter } from 'src/core/common/hooks';
import CircularProgress from '../CircularProgress';
import styles from './Button.module.scss';

export type ButtonProps = {
  link?: string;
  className?: string;
  id?: string;
  wrapperClassName?: string;
  labelClassName?: string;
  prevIcon?: ReactNode;
  afterIcon?: ReactNode;
  loading?: boolean;
  variant?: 'primary' | 'success' | 'outlined';
  iconClass?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      link,
      disabled,
      variant = 'primary',
      onClick,
      className = '',
      wrapperClassName,
      labelClassName,
      type = 'button',
      children,
      prevIcon,
      afterIcon,
      loading,
      iconClass = '',
      ...otherProps
    }: ButtonProps,
    ref,
  ) => {
    const router = useRouter();

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
      if (loading) return;

      if (onClick) {
        onClick(event);
      }
      if (link) setTimeout(() => router.push(link), 300);
    };

    const btnClasses = cn({
      [styles.button]: true,
      [styles.disabled]: disabled,
      [styles.buttonSuccess]: variant === 'success',
      [styles.buttonOutlined]: variant === 'outlined',
      [className]: !!className,
    });

    const renderPrevIcon = () => {
      const iconClasses = cn({
        [styles.buttonIcon]: true,
        [styles.buttonIconBefore]: !!children,
        [iconClass]: !!iconClass,
      });

      if (loading) {
        return (
          <span className={iconClasses}>
            <CircularProgress className={styles.loadingIndicator} size={20} />
          </span>
        );
      }

      if (prevIcon) {
        return <span className={iconClasses}>{prevIcon}</span>;
      }

      return null;
    };

    const renderAfterIcon = () => {
      const iconClasses = cn({
        [styles.buttonIcon]: true,
        [styles.buttonIconAfter]: !!children,
        [iconClass]: !!iconClass,
      });

      if (afterIcon) {
        return <span className={iconClasses}>{afterIcon}</span>;
      }

      return null;
    };

    return (
      <div className={cn(styles.block, wrapperClassName)}>
        <button
          {...otherProps}
          className={btnClasses}
          type={type}
          onClick={handleClick}
          disabled={disabled}
          ref={ref}
        >
          <span className={cn(styles.buttonLabel, labelClassName)}>
            {renderPrevIcon()}
            {children}
            {renderAfterIcon()}
          </span>
        </button>
      </div>
    );
  },
);

export type TextButtonProps = Omit<ButtonProps, 'variant'>;

export const TextButton = ({ className, ...otherProps }: TextButtonProps) => (
  <Button className={cn(styles.textButton, className)} {...otherProps} />
);

export default Button;
