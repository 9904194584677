import {
  ContentManagerButton,
  ContentManagerImage,
  ContentManagerLink,
  ContentManagerRichText,
} from './shared';

export enum ContentManagerSectionsType {
  STATS_SECTION = 'sections.stats-section',
  SIMPLE_STATS_SECTION = 'sections.simple-stats-section',
  TOPIC_SECTION = 'sections.topic-section',
  REVIEW_SECTION = 'sections.review-section',
  SIMPLE_REVIEW_SECTION = 'sections.simple-review-section',
  APPLICATION_ADVANTAGES_SECTION = 'sections.application-advantages-section',
  BONUSES_SECTION = 'sections.bonuses-section',
  HOW_SECTION = 'sections.how-section',
  FAQ_SECTION = 'sections.faq-section',
  SEO_TEXT_SECTION = 'sections.text-section',
  SIMPLE_SEO_TEXT_SECTION = 'sections.simple-text-section',
  WHY_SECTION = 'sections.why-section',
  PROBLEMS_SECTION = 'sections.problems-section',
  CALL_TO_ACTION_SECTION = 'sections.call-to-action-section',
  SIMPLE_LINKS_SECTION = 'sections.simple-links-section',
  COMPARISON_SECTION = 'sections.comparison-section',
  VIDEO_SECTION = 'sections.video-section',
  TRAITS_SECTION = 'sections.traits-section',
  LINKS_SECTION = 'sections.links-section',
  CARDS_SECTION = 'sections.cards-section',
  CIRCLES_SECTION = 'sections.circles-section',
  PANELS_SECTION = 'sections.panels-section',
  REELS_SECTION = 'sections.reels-section',
  CHART_WITH_REDIRECT_SECTION = 'sections.chart-with-redirect-section',
}

type BasicSection = {
  id: number;
  component: ContentManagerSectionsType;
};

export type StatsSectionItem = {
  amount: string;
  id: number;
  object: string;
  units: string;
};

export type StatsSection = BasicSection & {
  component: ContentManagerSectionsType.STATS_SECTION;
  quote: string;
  stats: Array<StatsSectionItem>;
};

export type SimpleStatsSection = Pick<StatsSection, 'stats' | 'id'> & {
  component: ContentManagerSectionsType.SIMPLE_STATS_SECTION;
};

export type TopicSectionItem = {
  icon: ContentManagerImage;
  title: string;
  id: number;
  link: ContentManagerLink;
};

export type TopicSection = BasicSection & {
  title: string;
  component: ContentManagerSectionsType.TOPIC_SECTION;
  topics: Array<TopicSectionItem>;
};

export type ApplicationAdvantagesSectionItem = { id: number; text: string };

export type ApplicationAdvantagesSection = BasicSection & {
  title: string;
  advantages: Array<ApplicationAdvantagesSectionItem>;
  imageMobile: ContentManagerImage;
  imageDesktop: ContentManagerImage;
  component: ContentManagerSectionsType.APPLICATION_ADVANTAGES_SECTION;
  button: string;
};

export type ReviewSectionItem = {
  id: number | string;
  text: string;
  rating: number;
  customerName: string;
};

export type ReviewSection = BasicSection & {
  reviews: Array<ReviewSectionItem>;
  component: ContentManagerSectionsType.REVIEW_SECTION;
  title: string;
  description: string;
};

export type SimpleReviewSection = Pick<ReviewSection, 'reviews' | 'id'> & {
  component: ContentManagerSectionsType.SIMPLE_REVIEW_SECTION;
  title?: string;
};

type BonusesSectionItem = {
  id: number;
  title: string;
  description: string;
  imageDesktop: ContentManagerImage;
  imageMobile: ContentManagerImage;
};

export type RewardsItem = { id: number; text: string };

export type BonusesSection = BasicSection & {
  bonusesItems: Array<BonusesSectionItem>;
  component: ContentManagerSectionsType.BONUSES_SECTION;
  rewardsTitle: string;
  rewardsDescription: string;
  rewardsImageDesktop: ContentManagerImage;
  rewardsImageMobile: ContentManagerImage;
  rewardsItems: Array<RewardsItem>;
};

export type HowItem = {
  id: string;
  title: string;
  description: string;
};

export type HowSection = BasicSection & {
  button: ContentManagerButton;
  howItems: Array<HowItem>;
  title: string;
  component: ContentManagerSectionsType.HOW_SECTION;
};

export type FaqSectionItem = {
  id: number;
  title: string;
  text: string;
};

export type FaqSection = BasicSection & {
  title?: string;
  description?: string;
  component: ContentManagerSectionsType.FAQ_SECTION;
  questions: Array<FaqSectionItem>;
};

export type SeoTextSection = BasicSection & {
  component: ContentManagerSectionsType.SEO_TEXT_SECTION;
  title: string | null;
  text: string;
  hasBackground: boolean;
};

export type SimpleSeoTextSection = BasicSection & {
  component: ContentManagerSectionsType.SIMPLE_SEO_TEXT_SECTION;
  text: string;
  hasBackground: boolean;
  isWide: boolean;
};

export type WhySectionItem = {
  id: number;
  title: string;
  description: string;
  icon: ContentManagerImage;
};

export type WhySection = BasicSection & {
  title: string;
  component: ContentManagerSectionsType.WHY_SECTION;
  items: Array<WhySectionItem>;
  button: ContentManagerButton;
};

export type ProblemsSectionItem = {
  id: number;
  title: string;
  description: string;
};

export type ProblemsSection = BasicSection & {
  title: string;
  component: ContentManagerSectionsType.PROBLEMS_SECTION;
  items: Array<ProblemsSectionItem>;
};

export type CallToActionSection = BasicSection & {
  component: ContentManagerSectionsType.CALL_TO_ACTION_SECTION;
  imageDesktop: ContentManagerImage;
  imageMobile: ContentManagerImage;
};

export type SimpleLinksSection = BasicSection & {
  component: ContentManagerSectionsType.SIMPLE_LINKS_SECTION;
  title: string;
};

export type ComparisonSection = BasicSection & {
  component: ContentManagerSectionsType.COMPARISON_SECTION;
  title: string;
  prosTitle: string;
  prosItems: Array<ComparisonSectionItem>;
  consTitle: string;
  consItems: Array<ComparisonSectionItem>;
};

export type ComparisonSectionItem = {
  text: string;
};

export type VideoSection = BasicSection & {
  component: ContentManagerSectionsType.VIDEO_SECTION;
  title?: string;
  videoId: string;
  videoAlt: string;
};

export type TraitsSection = BasicSection & {
  component: ContentManagerSectionsType.TRAITS_SECTION;
  title: string;

  type: 'positive' | 'negative';
  image: ContentManagerImage;
  traits: string;
};

export type LinksSection = BasicSection & {
  component: ContentManagerSectionsType.LINKS_SECTION;
  title: string;
  items: Array<LinksSectionItem>;
};

export type LinksSectionItem = {
  id: number;
  title: string;
  image: ContentManagerImage;
  url: string;
};

export type PanelsSection = BasicSection & {
  component: ContentManagerSectionsType.PANELS_SECTION;
  items: Array<PanelsSectionItem>;
  button: string;
  title: string;
};

export type PanelsSectionItem = {
  id: number;
  title: string;
  text: ContentManagerRichText;
  image: ContentManagerImage;
};

export type CardsSection = BasicSection & {
  component: ContentManagerSectionsType.CARDS_SECTION;
  items: Array<CardsSectionItem>;
  button: string;
  title: string;
};

export type CardsSectionItem = {
  id: number;
  title: string;
  background: string;
  text: ContentManagerRichText;
  image: ContentManagerImage;
};

export type CirclesSection = BasicSection & {
  component: ContentManagerSectionsType.CIRCLES_SECTION;
  items: Array<CirclesSectionItem>;
  button: string;
  title: string;
  description: string;
};

export type CirclesSectionItem = {
  id: number;
  label: string;
  text: string;
  color: string;
};

export type ReelsSection = BasicSection & {
  component: ContentManagerSectionsType.REELS_SECTION;
  title: string;
  items: Array<ReelsSectionItem>;
};

export type ReelsSectionItem = {
  id: number;
  url: string;
  image: ContentManagerImage;
  name: string;
  videoId: string;
  videoAlt: string;
};

export type ChartWithRedirectSection = BasicSection & {
  title: ContentManagerRichText;
  button: string;
  redirectURL: string;
  image: ContentManagerImage;
  items: Array<ChartWithRedirectSectionItem>;
  component: ContentManagerSectionsType.CHART_WITH_REDIRECT_SECTION;
};

export type ChartWithRedirectSectionItem = {
  id: number;
  text: string;
};
