import cn from 'classnames';
import { MouseEventHandler } from 'react';
import { TextButton } from 'src/core/common/components/common/Button';
import styles from './ExpertCardMore.module.scss';

export type ExpertCardMoreProps = {
  onReadMore: () => void;
  className?: string;
};

const ExpertCardMore = ({ onReadMore, className }: ExpertCardMoreProps) => {
  const onClick: MouseEventHandler = (event) => {
    event.stopPropagation();
    onReadMore();
  };
  return (
    <TextButton
      wrapperClassName={styles.wrapper}
      className={cn(styles.root, className)}
      onClick={onClick}
    >
      Read more
    </TextButton>
  );
};

export default ExpertCardMore;
