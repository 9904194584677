import { useCachedQuery } from 'src/core/queryCache/hooks';
import { QueryCacheProviderOptions } from 'src/core/queryCache/entities';

export type ExpertsParams = {
  key: string;
  params: { perPage: number; page?: number; customerSource: 'seo' };
  options?: QueryCacheProviderOptions;
};

export const useExperts = ({
  key,
  params: { perPage, page, customerSource },
  options,
}: ExpertsParams) => {
  const {
    data = [],
    isLoading,
    isValidating,
  } = useCachedQuery(
    key,
    ({ services }) => {
      const { expertService } = services;

      return () => expertService.getExperts({ perPage, page, customerSource });
    },
    options,
  );

  return { experts: data, expertsAreLoading: isLoading || isValidating };
};
