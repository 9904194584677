import useSWR from 'swr';
import { Fetcher } from 'swr/_internal';
import { useStore } from 'react-redux';
import { useServices } from 'src/core/common/hooks';
import { Services } from 'src/core/common/context';
import { QueryCacheProviderOptions } from 'src/core/queryCache/entities';
import { RootInitialState } from 'src/core/common/store/rootReducer';

type UseCachedQueryExtra = {
  services: Services;
  getState: () => RootInitialState;
};

type CachedQueryFetcherCreator<Data, Key> = ({
  services,
  getState,
}: UseCachedQueryExtra) => Fetcher<Data, Key>;

/* TODO:
    1. Extend key to accept types others than string
    2. Allow options to be an Optional Parameter
 */
export const useCachedQuery = <
  Data = unknown,
  Error = { message: string },
  Key = string,
  Options = QueryCacheProviderOptions,
>(
  key: Key,
  fetcherCreator: CachedQueryFetcherCreator<Data, Key>,
  options: Options,
) => {
  const store = useStore();
  const services = useServices();

  return useSWR<Data, Error, Key, Options>(
    key,
    fetcherCreator({ services: services, getState: store.getState }),
    options,
  );
};
