import { useMemo, useState } from 'react';
import cn from 'classnames';
import ExpertCardMore from '../ExpertCardMore';
import styles from './ExpertAbout.module.scss';

export type ExpertAboutProps = {
  title?: string;
  maxDescriptionLength?: number;
  className?: string;
  descriptionProps: {
    className?: string;
    text: string;
  };
};

const ExpertAbout = ({
  descriptionProps,
  title,
  maxDescriptionLength = 220,
  className = '',
}: ExpertAboutProps) => {
  const [isShowMore, setShowMore] = useState(false);
  const shouldShowMore = !isShowMore && descriptionProps.text.length > maxDescriptionLength;

  const onReadMore = () => setShowMore(true);

  const normalizedDescription = useMemo(() => {
    if (shouldShowMore) return `${descriptionProps.text.slice(0, maxDescriptionLength)}...`;

    return descriptionProps.text;
  }, [descriptionProps, maxDescriptionLength, shouldShowMore]);

  return (
    <div className={className}>
      {!!title && <h5 className={styles.title}>{title}</h5>}
      <div className={cn(styles.text, descriptionProps.className)}>
        {normalizedDescription}{' '}
        {shouldShowMore && <ExpertCardMore className={styles.aboutMore} onReadMore={onReadMore} />}
      </div>
    </div>
  );
};

export default ExpertAbout;
