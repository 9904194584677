import { MouseEvent } from 'react';
import cn from 'classnames';
import ExpertAbout from 'src/core/common/components/common/ExpertCard/ExpertAbout';
import Image from 'src/core/common/components/common/Image';
import { Expert, getExpertTotalReviewCount } from 'src/core/experts/entities';
import Button from 'src/core/common/components/common/Button';
import Icon from 'src/core/svg-sprites/ui/atoms/Icon';
import styles from './ExpertCard.module.scss';

export type ExpertCardProps = {
  className?: string;
  onStartChat: () => void;
  info: Expert;
  onExpertClick?: () => void;
};

const ExpertCard = ({ className = '', onStartChat, info, onExpertClick }: ExpertCardProps) => {
  const onStartChatClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onStartChat();
  };

  return (
    <div className={cn(styles.root, className)}>
      <button
        className={styles.overlayButton}
        aria-label={`Select ${info.name}`}
        onClick={onExpertClick}
      />
      <div className={styles.content}>
        <div className={styles.imageWrapper}>
          <Image
            type="future"
            sizes="100%"
            fill
            className={styles.image}
            src={info.image}
            alt={info.name}
          />
        </div>
        <div className={styles.nameWrapper}>
          <span className={styles.name}>{info.name}</span>
        </div>
        <p className={styles.status}>{info.status}</p>
        <div className={styles.reviewWrapper}>
          <div className={styles.rating}>
            {info.rating}{' '}
            <Icon name="star-five-points" className={styles.star} title="stars rating" />
          </div>
          <span className={styles.reviews}>{getExpertTotalReviewCount(info)} reviews</span>
        </div>
        <div className={styles.divider}></div>
        <div className={styles.experienceWrapper}>
          <div className={styles.experienceItem}>
            <Icon name="hands-holding-heart" className={styles.icon} />
            <span className={styles.experienceAmount}>{info.experience} years</span> of experience
          </div>
          <div className={styles.experienceItem}>
            <Icon name="chat-bubble" className={styles.icon} />
            <span className={styles.experienceAmount}>{info.total_orders}</span>
            consultations done
          </div>
        </div>
        <ExpertAbout
          className={styles.description}
          maxDescriptionLength={88}
          descriptionProps={{ text: info.description }}
        />
        <div className={styles.action}>
          <p className={styles.cost}>Chat for {info.chatPriceInCredits} credits/min</p>
          <Button
            wrapperClassName={styles.buttonWrapper}
            className={styles.button}
            onClick={onStartChatClick}
          >
            Start chat
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ExpertCard;
